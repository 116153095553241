
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { Suspense, useMemo } from 'react';
import PageTitle from 'src/components/PageTitle';
import SuspenseFallbackSpinner from 'src/components/SuspenseFallbackSpinner';
import { useBrevoEmailEnabled } from 'src/hooks/use-brevo-email-enabled';
const Home = () => {
    const { t } = useTranslation('home');
    const isIntegratedDashboard = useBrevoEmailEnabled();
    const HomeModule = useMemo(() => isIntegratedDashboard
        ? dynamic(() => import('src/modules/home/views'), { suspense: true })
        : dynamic(() => import('src/modules/webpush/views'), {
            suspense: true,
        }), [isIntegratedDashboard]);
    return (<>
      <PageTitle title={t('Home')}/>
      <Suspense fallback={<SuspenseFallbackSpinner />}>
        <HomeModule />
      </Suspense>
    </>);
};
const getServerSideProps = async (ctx) => {
    if (ctx.query.host && ctx.query.embedded !== '1') {
        try {
            const response = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}/auth/shopify/shopify-auth${ctx.req.url}`);
            const data = await response.json();
            const { is_existing_user: isExistingUser, redirect_url } = data;
            if (!isExistingUser && redirect_url) {
                return {
                    redirect: {
                        destination: redirect_url,
                        permanent: false,
                    },
                    props: {},
                };
            }
        }
        catch (e) {
            return {
                props: {},
            };
        }
    }
    return {
        props: {},
    };
};
export default Home;

    async function __Next_Translate__getServerSideProps__1927a3a3002__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__1927a3a3002__ as getServerSideProps }
  